import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar, private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse | any) => {
        if (error.status === 403) {
          this.router.navigate(['/login']);
          return throwError(() => error);
        } else {
          if (request.headers.has('disable_snackbar') && request.headers.get('disable_snackbar') === 'true') {
            return throwError(() => error);
          }
          const message = 'Unknown error occurred, kindly retry.';
          this.snackBar.open(error?.error?.exception?.message || message, 'Dismiss', {
            duration: 3000,
          });
          return throwError(() => error);
        }
      })
    );
  }
}
